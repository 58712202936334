import React from 'react';
import {getLink, getRoutes} from "../../components/shared/routes";
import RedirectTo from "../../components/shared/RedirectTo/RedirectTo";

export default () => (<RedirectTo url={getLink([getRoutes().UseCases])} />);

// import React from 'react';
// import Layout from '../../components/shared/Layout';
// import SEO from '../../components/shared/SEO';
// import {useTranslation} from 'react-i18next';
// import css from './real_estate.module.scss';
// import Footer from "../../components/shared/Footer/Footer";
// import {graphql, useStaticQuery} from 'gatsby';
// import {getImagesFluid} from '../../components/componentsUtils';
// import CoveredVideo from "../../components/shared/CoveredVideo/CoveredVideo";
// import SlideTypeF from "../../components/shared/ImageSlider/SlideTypeF";
// import SlideTypeG from "../../components/shared/ImageSlider/SlideTypeG";
// import Movie from "../../movies/Inspirations/RealEstate/inspirations-realestate.mp4";
// import RealEstateIconBlack from "../../images/icons/real_estate_b.svg";
// import GoToButton from "../../components/shared/GoToButton/GoToButton";
// import {getId, getLink, getRoutes} from "../../components/shared/routes";
// import DocumentIconGreen from "../../images/icons/document_green.svg";


// const query = graphql`
// {
//   fluid: allFile(filter: {sourceInstanceName: {eq: "images_RealEstate"}}) {
//     nodes {
//       childImageSharp {
//         fluid(maxWidth: 1090, quality: 70) {
//           ...GatsbyImageSharpFluid,
//           originalName
//         }
//       }
//     }
//   }
// }`;


// const RealEstatePage = () => {
//   const { t } = useTranslation();
//   const imagesFluid = getImagesFluid(useStaticQuery(query));

//   const pageTitle = `${getRoutes().RealEstate.pageTitle} | ${getRoutes().Inspirations.pageTitle}`;


//   return (
//     <Layout>
//       <SEO title={pageTitle} description={'Pro images impact the property value'}/>

//       <div className={css.wrapper}>

//         <div className={css.realEstate} id={getRoutes().Inspirations.RealEstate}>
          
//         <div className={css.wrapper}>
//         <div className={css.slide1}>
//           <CoveredVideo
//               videoSrcURL={Movie}
//               placeholderImage={'Real_Estate_1_placeholder.jpg'}
//               imagesFluid={imagesFluid}
//               icon={RealEstateIconBlack}
//               title="Real Estate"
//               text="Feel as you were there and have the natural view."
//               buttons={(
//                 <>
//                     <GoToButton
//                         sectionUrl={getLink([getRoutes().RealEstate, getRoutes().RealEstate.sections.getStarted])}
//                         text="Get started!"
//                     />
//                     <GoToButton
//                         externalPageUrl={'/RE_01/OptiwAI FOR REAL ESTATE 01.pdf'}
//                         icon={DocumentIconGreen}
//                         filled={false}
//                     />
//                 </>
//               )}
//           />
//         </div>
//         <div className={css.slide2} id={getRoutes().RealEstate.sections.getStarted}>
//           <SlideTypeF
//               title={<>Professional photo <br/>quality enhancement</>}
//               text3={<p>Final optimisation result is just a visual improvement, <br/>without changing or modifying true context of the photo.</p>}
//               fullImage={'Real_Estate_2.jpg'}
//               fullImageAlt="optimisation anonymisation auto cropping"
//               phoneImage1={'Real_Estate_mobile_1.jpg'}
//               phoneImage1Alt="optimisation anonymisation auto cropping"
//               phoneImage1Height={1100}
//               imagesFluid={imagesFluid}
//               imagePosition={'right'}
//           />
//         </div>
//         <div className={css.slide3}>
//           <SlideTypeF
//               title={<>Professional photo <br/>quality editing</>}
//               text3={<p>Editing selected functionality interferes into <br/>the photo parameters and the final result <br/>is a manipulated reconstruction.</p>}
//               fullImage={'Real_Estate_3.jpg'}
//               fullImageAlt="inpainting upscaling artifacts removing"
//               phoneImage1={'Real_Estate_mobile_4.jpg'}
//               phoneImage1Alt="inpainting upscaling artifacts removing"
//               phoneImage1Height={1100}
//               imagesFluid={imagesFluid}
//           />
//         </div>
//         <div className={css.slide4}>
//           <SlideTypeG
//               title="Define your workflow requirements"
//               fullImage={'Real_Estate_4.jpg'}
//               phoneImage={'Real_Estate_mobile_7.jpg'}
//               alt="Custom workflow"
//               imagesFluid={imagesFluid}
//           />
//         </div>
//     </div>
//         </div>

//         <div className={css.Footer}>
//           <Footer />
//         </div>

//       </div>
//     </Layout>
//   );
// };

// export default RealEstatePage;
